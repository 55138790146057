.btn {
	text-align: center;
	a {
		display: inline-block;
		line-height: 1;
		padding: 1rem 2rem;
		border-radius: 4px;
		background-color: $gray;
		&:hover {
			background-color: darken($gray,15%);
		}
	}
	.scale {
		&:hover {
			transform: scale(1.1);
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.38);
		}
	}
}

.regular {
	display: inline-block;
	position: relative;
	&::before {
		content: "";
		background-color: $pink;
		width: 1rem;
		height: 2px;
		left: -.5rem;
		@extend .centeringY;
		transition: all .4s;
	}
	&::after {
		content: "";
		background-color: $green;
		width: .5rem;
		height: .5rem;
		border-radius: 50%;
		border: 1px solid #fff;
		position: absolute;
		bottom: -3px;
		right: 1rem;
		transition: all .4s;
	}
	&:hover {
		&::before {
			background-color: #fff;
			left: calc(100% - .5rem);
		}
		&::after {
			right: calc(100% - 1rem);
		}
		a {
			background-color: $pink;
			color: #fff;
			letter-spacing: 4px;
		}
	}
	a {
		display: inline-block;
		line-height: 1;
		padding: .75rem 1rem;
		border-radius: 8px;
		color: $pink;
		border: 1px solid $pink;
		font-size: 1rem;
		font-weight: normal;
	}
}


.menu-btn {
	width: 3rem;
	height: 2rem;
	overflow: hidden;
	cursor: pointer;
	.line {
		background-color: $black;
		width: 100%;
		position: absolute;
		height: 2px;
		border-radius: 64px;
		transition: all .4s;
	}
	.line1 {
		top: 0;
		left: 0;
	}
	.line2 {
		@extend .centeringY;
		left: 0;
	}
	.line3 {
		bottom: 0;
		left: 0;
	}
	&.open {
		.line1 {
			top: 17px;
			left: -5px;
			width: calc(100% - 9px);
			transform: rotate(45deg);
		}
		.line2 {
			left: -3rem;
		}
		.line3 {
			bottom: 13px;
			left: -5px;
			transform: rotate(-45deg);
			width: calc(100% - 9px);
		}
	}
}

.pagetop {
	text-align: center;
	font-size: 2rem;
}


@include mq {
	.menu-btn.open {
		.line {
			background-color: #fff;
		}
		.line1 {
			top: 10px;
			left: 0px;
		}
		.line3 {
			bottom: 8px;
			left: 0px;
		}
	}
}


@include mq(ssp) {
	.btn {
		a {
			display: block;
			text-align: center;
		}
	}
	.regular {
		display: inline-block;
		position: relative;
		display: block;
		text-align: center;
		a {
			display: block;
		}
	}
	// .menu-btn.open {
	// 	.line1 {
	// 		top: 11px;
	// 		left: -4px;
	// 	}
	// 	.line3 {
	// 		bottom: 11px;
	// 		left: -4px;
	// 	}
	// }
}
