.interview-tab {
	display: flex;
	justify-content: space-around;
	margin-bottom: .5rem;
	border-radius: 3rem 3rem 0 0;
	padding: 2rem 4rem 1.5rem;
	background-color: #fff;
	.item {
		cursor: pointer;
		padding-bottom: .5rem;
		&:hover {
			opacity: .5;
		}
		&.select {
			border-bottom: 1px solid $red;
			color: $red;
			font-weight: bold;
		}
		a {
			
		}
	}
}
.interview-box {
	background-color: #fff;
	border-radius: 0 0 3rem 3rem;
	padding: 2rem 4rem 1.5rem;
	img {
		width: 100%;
	}
	figure {
		margin: 2rem auto;
	}
	p {
		margin-bottom: 2rem;
	}
	iframe {
		margin: .5rem auto;
	}
	.item {
		display: none;
		&.select {
			display: block;
		}
		.title {
			color: $black;
			font-weight: normal;
			margin-bottom: 1.5rem;
		}
		.text {
			margin: 0;
		}
		.content {
			margin-bottom: 2.5rem;
		}
	}
}

@include mq {
	.interview-box {
		padding: 2rem 2rem 1.5rem;
		.item {
			.display-table {
				display: block;
				.cell {
					display: block;
					width: 100%;
					padding: 1rem 0;
					&:first-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}
}
