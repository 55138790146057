
// title
.title {
	font-family: $mincho;
	font-weight: normal;
}
.site-title {
	font-size: .8rem;
	font-weight: normal;
}
.title-section {
	text-align: center;
	font-size: 2.5rem;
	color: $green;
	margin-bottom: 2.5rem;
	.inner {
		display: inline-flex;
		align-items: center;
		&::before {
			content: "";
			background: url(../images/icon/heading-icon-left.svg) no-repeat;
			background-size: contain;
			width: 2rem;
			height: 4rem;
			margin-right: 2rem;
		}
		&::after {
			content: "";
			background: url(../images/icon/heading-icon-right.svg) no-repeat;
			background-size: contain;
			width: 2rem;
			height: 4rem;
			margin-left: 2rem;
		}
	}
	.text {
		font-weight: normal;
	}
}

.title-bg-green {
	background-color: $green;
	color: #fff;
	font-weight: normal;
	padding: 2rem 0;
	font-size: 1.5rem;
	margin-bottom: 2rem;
}
.title-green {
	color: $green;
	margin: 4rem auto;
	font-weight: normal;
}


.en-title {
	color: $gray;
	font-size: 11rem;
	line-height: 1;
}

.border-title {
	font-weight: normal;
	margin-bottom: 2rem;
	span {
		position: relative;
		&::before {
			content: "";
			@extend .centeringY;
			left: -4rem;
			width: 2rem;
			height: 2px;
			background-color: $pink;
			border-radius: 26px;
		}
		&::after {
			content: "";
			@extend .centeringY;
			right: -4rem;
			width: 2rem;
			height: 2px;
			background-color: $pink;
			border-radius: 26px;
		}
	}
}



// page
.page-title {
	text-align: center;
	color: $green;
	font-size: 3.5rem;
	&::after {
		content: "";
		background: url(../images/icon/page-title.svg) no-repeat;
		width: 4rem;
		height: .8rem;
		background-size: contain;
		margin-top: .5rem;
		@extend .centeringX;
	}
}
.page-inner-title {
	color: $pink;
	font-size: 2rem;
	font-weight: normal;
	font-family: $mincho;
	margin-bottom: 3rem;
}
.page-inner-text {
	
}

.page-title2 {
	font-family: $mincho;

}

.page-subtitle {
	color: $green;
	text-align: center;
	margin: 0 !important;
	padding: 4rem 1rem;
	font-size: 1.5rem;
}

.page-content {
	.title-section {
		font-size: 2rem;
		.inner {
			&::before,&::after {
				width: 2rem;
				height: 3.05rem;
				position: relative;
				top: 3px;
			}
		}
	}
}

.archive-list-title {
	font-weight: normal;
	margin: 1rem auto;
	font-size: 1.5rem;
}


.philosophy-title {
	font-size: 2rem;
	color: $yellow;
	font-family: $mincho;
	font-weight: normal;
}


@include mq(slaptop) {
	.archive-list-title {
		font-size: 1.125rem;
	}
	.page-subtitle {
		padding: 1rem;
	}
	.title-section {
		font-size: 2rem;
		.inner {
			display: inline-flex;
			align-items: center;
			&::before,&::after {
				width: 1.5rem;
				height: 3rem;
			}
		}
	}

}


@include mq(wtab) {
	.title-section {
		font-size: 2.2vw;
		.inner {
			&::before,&::after {
				width: 1.5vw;
				height: 3vw;
			}
		}
	}
	.page-title {
		font-size: 4vw;
	}
}

@include mq(ssp) {
	.title-section {
		text-align: center;
		font-size: 5vw;
		color: #4FC994;
		margin-bottom: 1.5rem;
		.inner {
			&::before {
				width: 1rem;
				height: 2rem;
				margin-right: .75rem;
			}
			&::after {
				width: 1rem;
				height: 2rem;
				margin-left: .75rem;
			}
		}
	}
	.page-title {
		font-size: 10vw;
		line-height: 1.2;
		margin-bottom: 5rem;
		&::after {
			margin-top: 1.5rem;
		}
	}
	.page-inner-title {
		font-size: 1.75rem;
	}
}
