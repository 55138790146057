// https://fontawesome.com/v4.7.0/icons/
@charset "UTF-8";
@import '_var';

*,*::before,*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
*::before,*::after {
	display: block;
}
html {
	width: 100%;
	height: 100%;
	font-size: 16px;
	background-color: #fff;
}
body {
	margin: 0;
	font-size: 16px;
	color: $text;
	font-family: $basefont;
	font-weight: 400;
	word-break: break-all;
	line-height: 1.6;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	// letter-spacing: 1px;
}
a {
	transition: all .2s;
	text-decoration: none;
	color: $black;
	cursor: pointer;
	display: block;
}
a,span {
	color: inherit;
}
ul,dl {
	list-style: none;
}
dt,dd,th,td,ol {
}
input,textarea,select {
	appearance: none;
	line-height: 1.6;
	border: none;
}
input,textarea {
	display: inline-block;
	border: 1px solid;
	background: none;
	font-size: inherit;
	&::placeholder {
		font-size: .9rem;
	}
}
button {
	border: 0;
	outline: none;
	appearance: none;
	cursor: pointer;
	background: none;
}
hr {
	border-color: $gray;
	border-width: 1px 0 0 0;
	margin: 1em auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}
iframe {
	display: block;
	width: 100%;
	background-color: $gray;
}
address {
	font-style: normal;
	margin-top: 2rem;
}
table {
	width: 100%;
}
strong {
	font-size: 1.25rem;
}
address {
	margin: 0;
}


// inclulde 
@import '_mixins';
@import '_extends';
@import '_common';
@import "parts/atoms/_btn.scss";
@import "parts/atoms/_icon.scss";
@import "parts/atoms/_nav.scss";
@import "parts/atoms/font/_font.scss";
@import "parts/atoms/font/_title.scss";
@import "parts/molecules/_box.scss";
@import "parts/molecules/_footer.scss";
@import "parts/molecules/_frame.scss";
@import "parts/molecules/_global-nav.scss";
@import "parts/molecules/_header.scss";
@import "parts/molecules/_kv.scss";
@import "parts/molecules/_pagination.scss";
@import "parts/molecules/_tab.scss";

.visible-mb {
	display: none;
}

@include mq {
	.dn {
		display: block;
	}
	.tl-section {
		padding: 4rem 0;
	}
	.hidden-mb {
		display: none;
	}
	.visible-mb {
		display: block;
	}
}
