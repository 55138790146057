// single
.pagination-single {
	margin-top: 2rem;
	a {
		padding: .5rem;
		margin-right: .5rem;
		color: $black;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			color: $blue;
		}
	}
}
