.global-nav {
	color: $black;
	background-color: #fff;
	padding: 1rem 0;
	&.fixed {
		position: fixed;
		z-index: 999;
		width: 100%;
		top: 0;
		left: 0;
	}
}
.global-nav-cnt {
	width: calc(1200px + 2rem);
	max-width: calc(100% - 1.5rem);
	padding: 0 1rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	.cnt-left {
		width: 23%;
		flex-shrink: 0;
	}
	.cnt-center {
		width: 120px;
		flex-shrink: 0;
	}
	.cnt-right {
		width: 23%;
		flex-shrink: 0;
		justify-content: flex-end;
		display: flex;
	}
	.tel {
		color: $red;
		line-height: 1.4;
		.number {
			font-size: 1.6rem;
			font-weight: bold;
		}
	}
}




@include mq(wtab) {
	.global-nav-cnt {
		max-width: 80%;
	}
}

@include mq(slaptop) {
	.global-nav-cnt {
		width: calc(1000px + 2rem);
		max-width: calc(100% - 1.5rem);
	}
}

@include mq {
	.global-nav-cnt {
		color: #fff;
		.cnt-left {
			display: none;
		}
		.cnt-center {
			width: 5rem;
		}
		.cnt-right {
			nav {
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: -100vw;
				background-color: $green;
				transition: all .6s;
				&.open {
					left: 0;
				}
			}
		}
	}
	.site-title-box {
		display: none;
	}
	.menu-btn {
		display: block;
		position: absolute;
		top: 50%;
		right: .5rem;
		transform: translateY(-50%);
	}
	.menu-btn {
		width: 2rem;
		height: 1.25rem;
	}
}
