// decoration
.underline {
	text-decoration: underline;
}

.mincho {
	font-family: $mincho;
}

// サイズ
.small {
	font-size: .75rem;
}
.medium {
	font-size: 1.5rem;
}
.large {
	font-size: 2rem;
}

// 
.bold {
	font-weight: bold;
}

// color
.green {
	color: $green;
}
.red {
	color: $red;
}

// align
.center {
	text-align: center;
}
.right {
	text-align: right;
}
.vertical {
	writing-mode: vertical-rl;
	white-space: nowrap;
}

// text transform
.txt-tranform-capitalize {
	text-transform: capitalize;
}

// weight
.bold {
	font-weight: bold;
}

// link
.link {
	&:hover {
		color: $red;
	}
}
.link-inline {
	color: $green;
	text-decoration: underline;
	display: inline;
	margin-right: .25rem;
	margin-left: .25rem;
}
.link2 {
	&:hover {
		text-decoration: underline;
	}
}

.attention {
	color: #F92828;
}

.cursive {
	font-family: gioviale,sans-serif;
	font-weight: normal;
	font-style: normal;
	line-height: 1;
}


@include mq(slaptop) {
}

@include mq(wtab) {
	.small {
		font-size: 1vw;
	}
}
