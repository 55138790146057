.header-nav {
	display: flex;
	align-items: center;
	list-style: none !important;
	.menu-item {
		margin-right: 2rem;
		color: $red;
		position: relative;
		&:last-child {
			margin-right: 0;
		}
		.sub-menu {
			display: none;
			position: absolute;
			right: 0;
			width: 10rem;
			background: $red;
			padding: 1rem;
			a {
				color: #fff;
			}
			.menu-item {
				list-style: decimal;
				margin: 0 0 1rem 1rem;
				color: #fff;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

// plugin
.wp-pagenavi {
	display: flex;
	justify-content: center;
	align-items: center;
	a,span {
		border: none !important;
	}
	.pages {
		display: none;
	}
}




@include mq(wtab) {
	.header-nav {
		font-size: .8rem;
	}
}

@include mq(slaptop) {
	.header-nav {
		font-size: .9rem;
	}
}

@include mq {
	.header-nav {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		transform: translate(-50%,-50%);
		width: 100%;
		max-width: 90%;
		text-align: center;
		font-size: 2rem;
		font-weight: normal;
		.nav {
			margin: 4rem auto;
		}
		.logo {
			width: 12rem;
			margin: 0 auto;
		}
		.menu-item {
			margin-right: 0;
			margin-bottom: 4rem;
			color: #fff;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				display: inline-block;
				padding-bottom: .5rem;
				font-weight: inherit;
			}
		}
	}
}
@include mq(ssp) {
	.header-nav {
		font-size: 1rem;
		.nav {
			margin: 4rem auto;
		}
		.menu-item {
			margin-bottom: 1rem;
			a {
				color: #fff;
			}
		}
	}
}
