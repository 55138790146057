.kv {
	position: relative;
}
.to-scroll-wrap {
	position: absolute;
	bottom: 62px;
	left: 0;
	width: 100%;
}
.to-scroll {
	@extend .centering;
	width: 1.5rem;
	height: 3.25rem;
	background-color: #fff;
	border: 1px solid $red;
	border-radius: .75rem;
	.line {
		content: "";
		width: 6px;
		height: 45%;
		border-radius: 50px;
		background-color: $red;
		z-index: 1;
		overflow: hidden;
		@extend .centering;
		&:after {
			content: "";
			width: inherit;
			height: 100%;
			background-color: #ffa1a1;
			z-index: 2;
			position: relative;
			// transition: all .6s;
			animation: animation-haniwaman 2s linear 0s infinite normal;
			@keyframes animation-haniwaman {
				0% {top: -100%;}
				25% {top: -25%}
				50% {top: 100%;}
				100% {top: 200%;}
			}
		}
	}
}


@include mq(ssp) {
	.to-scroll-wrap {
		bottom: 0px;
	}
}
