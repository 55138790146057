// extend用
.flex {
	display: flex;
}
.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}
.ai-center {
	align-items: center;
}
.flex-between {
	display: flex;
	justify-content: space-between;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
//


.flex-50 {
	@extend .flex;
	& > .item {
		flex-basis: 50%;
	}
}
.flex-48 {
	@extend .flex-between;
	& > .item {
		flex-basis: 48%;
	}
}
.flex-32 {
	@extend .flex-between;
	& > .item {
		flex-basis: 32%;
	}
}

.flex-70-30 {
	@extend .flex;
	& > .item-l {
		flex-basis: 70%;
	}
	& > .item-r {
		flex-basis: 30%;
	}
}
.flex-40-60 {
	@extend .flex;
	& > .item-l {
		flex-basis: 40%;
	}
	& > .item-r {
		flex-basis: 60%;
	}
}
.flex-20-75 {
	@extend .flex-between;
	& > .item-l {
		flex-basis: 20%;
	}
	& > .item-r {
		flex-basis: 75%;
	}
}
.flex-25-70 {
	@extend .flex-between;
	& > .item-l {
		flex-basis: 25%;
	}
	& > .item-r {
		flex-basis: 70%;
	}
}
.flex-30-70 {
	@extend .flex-between;
	& > .item-l {
		flex-basis: 30%;
	}
	& > .item-r {
		flex-basis: 70%;
	}
}


.flex-wrap-32 {
	@extend .flex-wrap;
	.item {
		flex-basis: 32%;
		margin-right: 2%;
		margin-bottom: 2%;
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
.flex-wrap-48 {
	@extend .flex-wrap;
	.item {
		flex-basis: 48%;
		margin-right: 4%;
		margin-bottom: 4%;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	&.v2 {
		.item {
			&:first-child {
				flex-basis: 100%;
			}
			&:nth-child(2n) {
				margin-right: 4%;
			}
			&:nth-child(odd) {
				margin-right: 0;
			}
		}
	}
}



// display
.display-table {
	display: table;
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 1px;
	border-bottom: 1px solid #eaeaea;
	.cell {
		display: table-cell;
		padding: 1rem;
		&:first-child {
			width: 8rem;
		}
	}
}
.between {
	display: flex;
	justify-content: space-between;
}
.block {
	display: block;
}


// wrapper 
.wrapper {
	width: calc(1200px + 2rem);
	max-width: calc(100% - 1.5rem);
	padding: 1rem;
	margin: 0 auto;
}
.section {
	margin: 7rem auto;
}
.section2 {
	margin: 2rem auto;
}
.wrapper0 {
	@extend .wrapper;
	padding: 0 1rem;
}


.figure {
	figcaption {
		padding: 1rem;
		line-height: 2;
	}
}


// page
.page-thumb-wrapper {
	// background-color: #efefef;
	// height: 26.45vw;
	// max-height: 500px;
	.cell {
		width: 50%;
	}
}
.page-thumb-wrapper2 {
	// @extend .page-thumb-wrapper;
	// background-color: $green;
}
.page-thumb {
	background-size: cover;
	background-position: center;
	height: 100%;
}
.page-title-wrap {
	padding-left: 5%;
	.title {
		font-weight: normal;
	}
}

.scroll-wrap {
	overflow: auto;
}





@include mq(slaptop) {
	.wrapper {
		width: calc(1000px + 2rem);
		max-width: calc(100% - 1.5rem);
	}
}

@include mq(wtab) {
	.wrapper {
		width: calc(1000px + 2rem);
		max-width: 80%;
	}
}

@include mq {
	.page-title-wrap .title {
		font-weight: normal;
		font-size: 1.5rem;
	}
}
@include mq (ssp) {
	.flex-50,.flex-wrap-48,.flex-48,.flex-wrap-32 {
		display: block;
		.item {
			margin-right: 0;
		}
	}
	.section {
		margin: 3rem auto;
	}
	.page-thumb-wrapper, .page-thumb-wrapper2 {
		.cell {
			display: block;
			width: 100%;
			padding: 0;
		}
		.page-thumb {
			// height: 9rem;
		}
		.page-title-wrap {
			.title {
				padding: 1rem;
				font-size: 1.25rem;
				text-align: center;
			}
		}
	}
	.wrapper {
		max-width: calc(100% - 2rem);
		padding: 0;
	}
}
