// single
.single-header {
	margin: 0 0 2rem;
	text-align: center;
	.title {
		font-size: 2rem;
		margin-bottom: 2.5rem;
		position: relative;
		&::after {
			content: "";
			width: 3rem;
			height: 2px;
			background: $yellow;
			position: absolute;
			bottom: -1rem;
			@extend .centeringX;
		}
	}
}

@include mq {
	.single-header {
		padding: 1rem;
		text-align: left;
		.title {
			font-size: 1.75rem;
			line-height: 1.4;
		}
	}
}
