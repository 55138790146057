.side-metabox {
	background-color: #fff;
	border-radius: 3rem;
	overflow: hidden;
	.thumb {
		height: 12rem;
		background-position: center;
		background-size: cover;
	}
	.text-box {
		padding: 1rem 2rem 1.5rem;
		p {
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
