.img-ie11 {
	width: 100%;
	height: auto;
}

.relative {
	position: relative;
}
.inline {
	display: inline;
}
.ib {
	display: inline-block;
}

.margin-4-auto {
	margin: 4rem auto;
}

.tl-section {
	padding: 6rem 0;
}

// プラグイン調整
.fbc-wrap {
	display: flex;
	justify-content: center;
}

.dn {
	display: none;
}

// color
.pink {
	color: $pink;
}
.bg-red {
	background-color: $red;
	color: #fff;
}
.bg-green {
	background-color: $green;
	color: #fff;
}

.comp-section-en {
	position: relative;
	margin: 4rem auto 8rem;
	.en-title-box {
		position: absolute;
		top: 0;
		left: -15%;
		height: 32rem;
		z-index: 3;
	}
}

.theme-hr {
	width: 100%;
	height: 2px;
	background: #EFEFEF;
	position: relative;
	margin: 7rem auto;
	&::after {
		content: "";
		width: 16px;
		height: 16px;
		background: #FF99CC;
		border: 4px solid #fff;
		border-radius: 50%;
		@extend .centering;
	}
}

.table {
	border-collapse: collapse;
	background: #fff;
	th,td {
		padding: 1rem;
		border: 1px solid $gray;
	}
	th {
		width: 30%;
	}
}

// top
.top-about-box {
	display: flex;
	justify-content: space-between;
	.item-l {
		flex-basis: 72%;
	}
	.title {
		color: $text-green;
		font-size: 4vw;
		white-space: nowrap;
		margin-left: 3rem;
		line-height: 1.5;
		letter-spacing: 4px;
	}
	.thumb {
		margin-bottom: 4rem;
		.img {
			border-radius: 2rem;
			position: relative;
			z-index: 2;
			box-shadow: 14px 13px 0px #FFE8F4;
			width: 100%;
		}
	}
}



// plugins
// tablepress
.tablepress {
	background: #fff !important;
	td {
		padding: 1rem !important;
		border: 1px solid $gray !important;
	}
	.column-1 {
		width: 30%;
	}
}

// loading
.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: rgba(255, 255, 255, 0.95);
	.inner {
		@extend .centering;
		width: 100%;
	}
	.logo {
		width: 10rem;
		margin: 0 auto 2rem;
	}
}
.dot {
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background-color: $green;
	color: transparent;
	margin: -1px 0;
}
.dot::before, .dot::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
	left: -50px;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background-color: $green;
	color: transparent;
	opacity: 0;
	animation: dotGathering 2s infinite ease-in;
}
.dot::after {
	animation-delay: .5s;
}
@keyframes dotGathering {
	0% {
		opacity: 0;
		transform: translateX(0);
	}
	35%,
	60% {
		opacity: 1;
		transform: translateX(50px);
	}
	100% {
		opacity: 0;
		transform: translateX(100px);
	}
}
.dots {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2rem 0;
	margin: 0 -5%;
	overflow: hidden;
}
// loading



@include mq(max) {
	.top-about-box {
		.title {
			font-size: 4rem;
		}
	}
}

@include mq(slaptop) {
	.top-about-box {
		.title {
			height: 37rem;
		}
	}
}

@include mq(laptop) {
	.comp-section-en .en-title-box {
		left: -11%;
	}
}

@include mq(wtab) {
	.top-about-box .title {
		height: 30rem;
	}
}

@include mq(ssp) {
	.comp-section-en .en-title-box {
		position: static;
		height: auto;
		.en-title {
			display: none;
		}
	}
	.top-about-box {
		position: relative;
		.title {
			writing-mode: initial;
			background: rgba(255, 255, 255, 0.65);
			padding: 1rem;
			font-size: 7.5vw;
			letter-spacing: 0;
			background: rgba(79, 201, 148, 0.9);
			color: #fff;
			margin-right: 20%;
			margin-left: -1rem;
		}
		.item-r {
			position: absolute;
			z-index: 3;
			bottom: 4rem;
			right: 0;
			width: 100%;
		}
		.item-l {
			flex-basis: 100%;
		}
	}
	.fbc-page .fbc-wrap .fbc-items li {
		a,span {
			font-size: 12px !important;
		}
	}
	.theme-hr {
		margin: 4rem auto;
	}
	.table {
		border-collapse: collapse;
		background: #fff;
		font-size: 12px;
	}
}
