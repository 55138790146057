@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*::before, *::after {
  display: block; }

html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: #fff; }

body {
  margin: 0;
  font-size: 16px;
  color: #456571;
  font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif;
  font-weight: 400;
  word-break: break-all;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; }

a {
  transition: all .2s;
  text-decoration: none;
  color: #464646;
  cursor: pointer;
  display: block; }

a, span {
  color: inherit; }

ul, dl {
  list-style: none; }

input, textarea, select {
  appearance: none;
  line-height: 1.6;
  border: none; }

input, textarea {
  display: inline-block;
  border: 1px solid;
  background: none;
  font-size: inherit; }
  input::placeholder, textarea::placeholder {
    font-size: .9rem; }

button {
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: none; }

hr {
  border-color: #EFEFEF;
  border-width: 1px 0 0 0;
  margin: 1em auto; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%;
  background-color: #EFEFEF; }

address {
  font-style: normal;
  margin-top: 2rem; }

table {
  width: 100%; }

strong {
  font-size: 1.25rem; }

address {
  margin: 0; }

.centering, .centering_fix, .theme-hr::after, .loading .inner, .to-scroll, .to-scroll .line {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY, .regular::before, .menu-btn .line2, .border-title span::before, .border-title span::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX, .page-title::after, .single-header .title::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.img-ie11 {
  width: 100%;
  height: auto; }

.relative {
  position: relative; }

.inline {
  display: inline; }

.ib {
  display: inline-block; }

.margin-4-auto {
  margin: 4rem auto; }

.tl-section {
  padding: 6rem 0; }

.fbc-wrap {
  display: flex;
  justify-content: center; }

.dn {
  display: none; }

.pink {
  color: #FF99CC; }

.bg-red {
  background-color: #FF625A;
  color: #fff; }

.bg-green {
  background-color: #4FC994;
  color: #fff; }

.comp-section-en {
  position: relative;
  margin: 4rem auto 8rem; }
  .comp-section-en .en-title-box {
    position: absolute;
    top: 0;
    left: -15%;
    height: 32rem;
    z-index: 3; }

.theme-hr {
  width: 100%;
  height: 2px;
  background: #EFEFEF;
  position: relative;
  margin: 7rem auto; }
  .theme-hr::after {
    content: "";
    width: 16px;
    height: 16px;
    background: #FF99CC;
    border: 4px solid #fff;
    border-radius: 50%; }

.table {
  border-collapse: collapse;
  background: #fff; }
  .table th, .table td {
    padding: 1rem;
    border: 1px solid #EFEFEF; }
  .table th {
    width: 30%; }

.top-about-box {
  display: flex;
  justify-content: space-between; }
  .top-about-box .item-l {
    flex-basis: 72%; }
  .top-about-box .title {
    color: #08C171;
    font-size: 4vw;
    white-space: nowrap;
    margin-left: 3rem;
    line-height: 1.5;
    letter-spacing: 4px; }
  .top-about-box .thumb {
    margin-bottom: 4rem; }
    .top-about-box .thumb .img {
      border-radius: 2rem;
      position: relative;
      z-index: 2;
      box-shadow: 14px 13px 0px #FFE8F4;
      width: 100%; }

.tablepress {
  background: #fff !important; }
  .tablepress td {
    padding: 1rem !important;
    border: 1px solid #EFEFEF !important; }
  .tablepress .column-1 {
    width: 30%; }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.95); }
  .loading .inner {
    width: 100%; }
  .loading .logo {
    width: 10rem;
    margin: 0 auto 2rem; }

.dot {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #4FC994;
  color: transparent;
  margin: -1px 0; }

.dot::before, .dot::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -50px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #4FC994;
  color: transparent;
  opacity: 0;
  animation: dotGathering 2s infinite ease-in; }

.dot::after {
  animation-delay: .5s; }

@keyframes dotGathering {
  0% {
    opacity: 0;
    transform: translateX(0); }
  35%,
  60% {
    opacity: 1;
    transform: translateX(50px); }
  100% {
    opacity: 0;
    transform: translateX(100px); } }

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden; }

@media screen and (min-width: 1441px) {
  .top-about-box .title {
    font-size: 4rem; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .top-about-box .title {
    height: 37rem; } }

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .comp-section-en .en-title-box {
    left: -11%; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .top-about-box .title {
    height: 30rem; } }

@media screen and (max-width: 600px) {
  .comp-section-en .en-title-box {
    position: static;
    height: auto; }
    .comp-section-en .en-title-box .en-title {
      display: none; }
  .top-about-box {
    position: relative; }
    .top-about-box .title {
      writing-mode: initial;
      background: rgba(255, 255, 255, 0.65);
      padding: 1rem;
      font-size: 7.5vw;
      letter-spacing: 0;
      background: rgba(79, 201, 148, 0.9);
      color: #fff;
      margin-right: 20%;
      margin-left: -1rem; }
    .top-about-box .item-r {
      position: absolute;
      z-index: 3;
      bottom: 4rem;
      right: 0;
      width: 100%; }
    .top-about-box .item-l {
      flex-basis: 100%; }
  .fbc-page .fbc-wrap .fbc-items li a, .fbc-page .fbc-wrap .fbc-items li span {
    font-size: 12px !important; }
  .theme-hr {
    margin: 4rem auto; }
  .table {
    border-collapse: collapse;
    background: #fff;
    font-size: 12px; } }

.btn {
  text-align: center; }
  .btn a {
    display: inline-block;
    line-height: 1;
    padding: 1rem 2rem;
    border-radius: 4px;
    background-color: #EFEFEF; }
    .btn a:hover {
      background-color: #c9c9c9; }
  .btn .scale:hover {
    transform: scale(1.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.38); }

.regular {
  display: inline-block;
  position: relative; }
  .regular::before {
    content: "";
    background-color: #FF99CC;
    width: 1rem;
    height: 2px;
    left: -.5rem;
    transition: all .4s; }
  .regular::after {
    content: "";
    background-color: #4FC994;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    bottom: -3px;
    right: 1rem;
    transition: all .4s; }
  .regular:hover::before {
    background-color: #fff;
    left: calc(100% - .5rem); }
  .regular:hover::after {
    right: calc(100% - 1rem); }
  .regular:hover a {
    background-color: #FF99CC;
    color: #fff;
    letter-spacing: 4px; }
  .regular a {
    display: inline-block;
    line-height: 1;
    padding: .75rem 1rem;
    border-radius: 8px;
    color: #FF99CC;
    border: 1px solid #FF99CC;
    font-size: 1rem;
    font-weight: normal; }

.menu-btn {
  width: 3rem;
  height: 2rem;
  overflow: hidden;
  cursor: pointer; }
  .menu-btn .line {
    background-color: #464646;
    width: 100%;
    position: absolute;
    height: 2px;
    border-radius: 64px;
    transition: all .4s; }
  .menu-btn .line1 {
    top: 0;
    left: 0; }
  .menu-btn .line2 {
    left: 0; }
  .menu-btn .line3 {
    bottom: 0;
    left: 0; }
  .menu-btn.open .line1 {
    top: 17px;
    left: -5px;
    width: calc(100% - 9px);
    transform: rotate(45deg); }
  .menu-btn.open .line2 {
    left: -3rem; }
  .menu-btn.open .line3 {
    bottom: 13px;
    left: -5px;
    transform: rotate(-45deg);
    width: calc(100% - 9px); }

.pagetop {
  text-align: center;
  font-size: 2rem; }

@media screen and (max-width: 980px) {
  .menu-btn.open .line {
    background-color: #fff; }
  .menu-btn.open .line1 {
    top: 10px;
    left: 0px; }
  .menu-btn.open .line3 {
    bottom: 8px;
    left: 0px; } }

@media screen and (max-width: 600px) {
  .btn a {
    display: block;
    text-align: center; }
  .regular {
    display: inline-block;
    position: relative;
    display: block;
    text-align: center; }
    .regular a {
      display: block; } }

.icon {
  display: inline-block;
  width: 1.25em;
  margin-right: .5rem;
  text-align: center; }

.header-nav {
  display: flex;
  align-items: center;
  list-style: none !important; }
  .header-nav .menu-item {
    margin-right: 2rem;
    color: #FF625A;
    position: relative; }
    .header-nav .menu-item:last-child {
      margin-right: 0; }
    .header-nav .menu-item .sub-menu {
      display: none;
      position: absolute;
      right: 0;
      width: 10rem;
      background: #FF625A;
      padding: 1rem; }
      .header-nav .menu-item .sub-menu a {
        color: #fff; }
      .header-nav .menu-item .sub-menu .menu-item {
        list-style: decimal;
        margin: 0 0 1rem 1rem;
        color: #fff; }
        .header-nav .menu-item .sub-menu .menu-item:last-child {
          margin-bottom: 0; }

.wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center; }
  .wp-pagenavi a, .wp-pagenavi span {
    border: none !important; }
  .wp-pagenavi .pages {
    display: none; }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .header-nav {
    font-size: .8rem; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .header-nav {
    font-size: .9rem; } }

@media screen and (max-width: 980px) {
  .header-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90%;
    text-align: center;
    font-size: 2rem;
    font-weight: normal; }
    .header-nav .nav {
      margin: 4rem auto; }
    .header-nav .logo {
      width: 12rem;
      margin: 0 auto; }
    .header-nav .menu-item {
      margin-right: 0;
      margin-bottom: 4rem;
      color: #fff; }
      .header-nav .menu-item:last-child {
        margin-bottom: 0; }
      .header-nav .menu-item a {
        display: inline-block;
        padding-bottom: .5rem;
        font-weight: inherit; } }

@media screen and (max-width: 600px) {
  .header-nav {
    font-size: 1rem; }
    .header-nav .nav {
      margin: 4rem auto; }
    .header-nav .menu-item {
      margin-bottom: 1rem; }
      .header-nav .menu-item a {
        color: #fff; } }

.underline {
  text-decoration: underline; }

.mincho {
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; }

.small {
  font-size: .75rem; }

.medium {
  font-size: 1.5rem; }

.large {
  font-size: 2rem; }

.bold {
  font-weight: bold; }

.green {
  color: #4FC994; }

.red {
  color: #FF625A; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.vertical {
  writing-mode: vertical-rl;
  white-space: nowrap; }

.txt-tranform-capitalize {
  text-transform: capitalize; }

.bold {
  font-weight: bold; }

.link:hover {
  color: #FF625A; }

.link-inline {
  color: #4FC994;
  text-decoration: underline;
  display: inline;
  margin-right: .25rem;
  margin-left: .25rem; }

.link2:hover {
  text-decoration: underline; }

.attention {
  color: #F92828; }

.cursive {
  font-family: gioviale,sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1; }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .small {
    font-size: 1vw; } }

.title {
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-weight: normal; }

.site-title {
  font-size: .8rem;
  font-weight: normal; }

.title-section {
  text-align: center;
  font-size: 2.5rem;
  color: #4FC994;
  margin-bottom: 2.5rem; }
  .title-section .inner {
    display: inline-flex;
    align-items: center; }
    .title-section .inner::before {
      content: "";
      background: url(../images/icon/heading-icon-left.svg) no-repeat;
      background-size: contain;
      width: 2rem;
      height: 4rem;
      margin-right: 2rem; }
    .title-section .inner::after {
      content: "";
      background: url(../images/icon/heading-icon-right.svg) no-repeat;
      background-size: contain;
      width: 2rem;
      height: 4rem;
      margin-left: 2rem; }
  .title-section .text {
    font-weight: normal; }

.title-bg-green {
  background-color: #4FC994;
  color: #fff;
  font-weight: normal;
  padding: 2rem 0;
  font-size: 1.5rem;
  margin-bottom: 2rem; }

.title-green {
  color: #4FC994;
  margin: 4rem auto;
  font-weight: normal; }

.en-title {
  color: #EFEFEF;
  font-size: 11rem;
  line-height: 1; }

.border-title {
  font-weight: normal;
  margin-bottom: 2rem; }
  .border-title span {
    position: relative; }
    .border-title span::before {
      content: "";
      left: -4rem;
      width: 2rem;
      height: 2px;
      background-color: #FF99CC;
      border-radius: 26px; }
    .border-title span::after {
      content: "";
      right: -4rem;
      width: 2rem;
      height: 2px;
      background-color: #FF99CC;
      border-radius: 26px; }

.page-title {
  text-align: center;
  color: #4FC994;
  font-size: 3.5rem; }
  .page-title::after {
    content: "";
    background: url(../images/icon/page-title.svg) no-repeat;
    width: 4rem;
    height: .8rem;
    background-size: contain;
    margin-top: .5rem; }

.page-inner-title {
  color: #FF99CC;
  font-size: 2rem;
  font-weight: normal;
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  margin-bottom: 3rem; }

.page-title2 {
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; }

.page-subtitle {
  color: #4FC994;
  text-align: center;
  margin: 0 !important;
  padding: 4rem 1rem;
  font-size: 1.5rem; }

.page-content .title-section {
  font-size: 2rem; }
  .page-content .title-section .inner::before, .page-content .title-section .inner::after {
    width: 2rem;
    height: 3.05rem;
    position: relative;
    top: 3px; }

.archive-list-title {
  font-weight: normal;
  margin: 1rem auto;
  font-size: 1.5rem; }

.philosophy-title {
  font-size: 2rem;
  color: #FFCF48;
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-weight: normal; }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .archive-list-title {
    font-size: 1.125rem; }
  .page-subtitle {
    padding: 1rem; }
  .title-section {
    font-size: 2rem; }
    .title-section .inner {
      display: inline-flex;
      align-items: center; }
      .title-section .inner::before, .title-section .inner::after {
        width: 1.5rem;
        height: 3rem; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .title-section {
    font-size: 2.2vw; }
    .title-section .inner::before, .title-section .inner::after {
      width: 1.5vw;
      height: 3vw; }
  .page-title {
    font-size: 4vw; } }

@media screen and (max-width: 600px) {
  .title-section {
    text-align: center;
    font-size: 5vw;
    color: #4FC994;
    margin-bottom: 1.5rem; }
    .title-section .inner::before {
      width: 1rem;
      height: 2rem;
      margin-right: .75rem; }
    .title-section .inner::after {
      width: 1rem;
      height: 2rem;
      margin-left: .75rem; }
  .page-title {
    font-size: 10vw;
    line-height: 1.2;
    margin-bottom: 5rem; }
    .page-title::after {
      margin-top: 1.5rem; }
  .page-inner-title {
    font-size: 1.75rem; } }

.side-metabox {
  background-color: #fff;
  border-radius: 3rem;
  overflow: hidden; }
  .side-metabox .thumb {
    height: 12rem;
    background-position: center;
    background-size: cover; }
  .side-metabox .text-box {
    padding: 1rem 2rem 1.5rem; }
    .side-metabox .text-box p {
      margin-bottom: 1rem; }
      .side-metabox .text-box p:last-child {
        margin-bottom: 0; }

.footer-bottom {
  padding: 1rem 0; }

.footer-about {
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: .5rem; }
  .footer-about .logo {
    flex-basis: 25%; }
  .footer-about .address {
    flex-basis: 70%; }
    .footer-about .address .title {
      font-size: 1.75rem;
      font-weight: bold;
      margin-bottom: .25rem; }

.footer-nav {
  display: flex;
  font-size: 14px; }
  .footer-nav > .item {
    display: flex;
    margin-right: 1rem; }
    .footer-nav > .item a::before, .footer-nav > .item span::before {
      content: "";
      background: url(../images/icon/arrow-r.svg) no-repeat;
      background-size: contain;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      position: relative;
      top: 2px; }
    .footer-nav > .item a:hover {
      color: #0090D5; }
    .footer-nav > .item.sub {
      display: block; }
    .footer-nav > .item .subnav {
      margin: .5rem 0 0 1rem; }
      .footer-nav > .item .subnav a::before, .footer-nav > .item .subnav span::before {
        background: url(../images/icon/list-item.svg) no-repeat;
        height: 10px;
        margin-right: .5rem; }
      .footer-nav > .item .subnav .item {
        margin-bottom: .25rem;
        font-size: 12px; }

.service-list {
  margin: 0 !important;
  list-style: none !important; }
  .service-list .thumb {
    overflow: hidden; }
    .service-list .thumb .img {
      border-radius: 1rem;
      transition: all .6s; }
      .service-list .thumb .img:hover {
        transform: scale(1.1); }
  .service-list .desc {
    margin: 1.5rem auto; }

.article-list {
  margin: 0rem auto 2rem; }
  .article-list .item {
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    border-bottom: 4px dotted #EFEFEF; }
    .article-list .item:last-child {
      margin-bottom: 0; }

.article-box .title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all .4s;
  position: relative;
  left: 0;
  padding: .5rem;
  font-size: 1rem;
  font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif;
  font-weight: normal; }

.article-box .date {
  margin-bottom: .5rem; }

.article-box:hover .title {
  text-decoration: underline;
  left: 1rem;
  background-color: #f8f8f8; }

.footer-contact .img {
  background: url(../images/thumb/contact.jpg) no-repeat center;
  background-size: cover; }

.footer-contact .cnt {
  background-color: #4FC994;
  padding: 4rem; }
  .footer-contact .cnt img {
    width: 38rem; }

.copyright {
  text-align: center; }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .service-list .desc {
    height: 6rem; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .service-list .desc {
    height: 7rem; }
  .footer-nav > .item {
    display: flex;
    margin-right: 1rem;
    font-size: 1vw; }
  .footer-about .address {
    font-size: 1vw; } }

@media screen and (max-width: 980px) {
  .slick-dots {
    bottom: -0.5rem !important; }
  .article-list {
    padding: 1.5rem; }
    .article-list time {
      display: block;
      margin-bottom: .5rem; }
    .article-list a {
      line-height: 1.4; }
  .footer-about {
    flex-wrap: wrap;
    display: flex !important; }
    .footer-about .item-l, .footer-about .item-r {
      flex-basis: 100% !important; }
    .footer-about .item-l {
      order: 2; }
    .footer-about .item-r {
      padding: 1rem;
      margin: 0; } }

@media screen and (max-width: 600px) {
  .service-list {
    display: block !important; }
    .service-list .item {
      margin-bottom: 2rem; }
    .service-list .desc {
      margin: 1rem auto; }
  .article-list {
    padding: 1.5rem;
    border: 1px solid #ebebeb; }
  .footer-contact .img {
    height: 11rem; }
  .footer-contact .cnt {
    padding: 1rem; }
  .footer-about {
    display: block !important;
    margin-bottom: 1.5rem; }
    .footer-about .logo {
      width: 50%;
      margin: 0 auto;
      margin-bottom: 2rem; }
  .footer-nav {
    display: block;
    margin-top: 1.5rem; }
    .footer-nav .item {
      margin-bottom: .5rem; } }

.flex, .flex-50, .flex-70-30, .flex-40-60 {
  display: flex; }

.flex-wrap, .flex-wrap-32, .flex-wrap-48 {
  display: flex;
  flex-wrap: wrap; }

.ai-center {
  align-items: center; }

.flex-between, .flex-48, .flex-32, .flex-20-75, .flex-25-70, .flex-30-70 {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-50 > .item {
  flex-basis: 50%; }

.flex-48 > .item {
  flex-basis: 48%; }

.flex-32 > .item {
  flex-basis: 32%; }

.flex-70-30 > .item-l {
  flex-basis: 70%; }

.flex-70-30 > .item-r {
  flex-basis: 30%; }

.flex-40-60 > .item-l {
  flex-basis: 40%; }

.flex-40-60 > .item-r {
  flex-basis: 60%; }

.flex-20-75 > .item-l {
  flex-basis: 20%; }

.flex-20-75 > .item-r {
  flex-basis: 75%; }

.flex-25-70 > .item-l {
  flex-basis: 25%; }

.flex-25-70 > .item-r {
  flex-basis: 70%; }

.flex-30-70 > .item-l {
  flex-basis: 30%; }

.flex-30-70 > .item-r {
  flex-basis: 70%; }

.flex-wrap-32 .item {
  flex-basis: 32%;
  margin-right: 2%;
  margin-bottom: 2%; }
  .flex-wrap-32 .item:nth-child(3n) {
    margin-right: 0; }

.flex-wrap-48 .item {
  flex-basis: 48%;
  margin-right: 4%;
  margin-bottom: 4%; }
  .flex-wrap-48 .item:nth-child(2n) {
    margin-right: 0; }

.flex-wrap-48.v2 .item:first-child {
  flex-basis: 100%; }

.flex-wrap-48.v2 .item:nth-child(2n) {
  margin-right: 4%; }

.flex-wrap-48.v2 .item:nth-child(odd) {
  margin-right: 0; }

.display-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1px;
  border-bottom: 1px solid #eaeaea; }
  .display-table .cell {
    display: table-cell;
    padding: 1rem; }
    .display-table .cell:first-child {
      width: 8rem; }

.between {
  display: flex;
  justify-content: space-between; }

.block {
  display: block; }

.wrapper, .wrapper0 {
  width: calc(1200px + 2rem);
  max-width: calc(100% - 1.5rem);
  padding: 1rem;
  margin: 0 auto; }

.section {
  margin: 7rem auto; }

.section2 {
  margin: 2rem auto; }

.wrapper0 {
  padding: 0 1rem; }

.figure figcaption {
  padding: 1rem;
  line-height: 2; }

.page-thumb-wrapper .cell {
  width: 50%; }

.page-thumb {
  background-size: cover;
  background-position: center;
  height: 100%; }

.page-title-wrap {
  padding-left: 5%; }
  .page-title-wrap .title {
    font-weight: normal; }

.scroll-wrap {
  overflow: auto; }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .wrapper, .wrapper0 {
    width: calc(1000px + 2rem);
    max-width: calc(100% - 1.5rem); } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .wrapper, .wrapper0 {
    width: calc(1000px + 2rem);
    max-width: 80%; } }

@media screen and (max-width: 980px) {
  .page-title-wrap .title {
    font-weight: normal;
    font-size: 1.5rem; } }

@media screen and (max-width: 600px) {
  .flex-50, .flex-wrap-48, .flex-48, .flex-wrap-32 {
    display: block; }
    .flex-50 .item, .flex-wrap-48 .item, .flex-48 .item, .flex-wrap-32 .item {
      margin-right: 0; }
  .section {
    margin: 3rem auto; }
  .page-thumb-wrapper .cell, .page-thumb-wrapper2 .cell {
    display: block;
    width: 100%;
    padding: 0; }
  .page-thumb-wrapper .page-title-wrap .title, .page-thumb-wrapper2 .page-title-wrap .title {
    padding: 1rem;
    font-size: 1.25rem;
    text-align: center; }
  .wrapper, .wrapper0 {
    max-width: calc(100% - 2rem);
    padding: 0; } }

.global-nav {
  color: #464646;
  background-color: #fff;
  padding: 1rem 0; }
  .global-nav.fixed {
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0; }

.global-nav-cnt {
  width: calc(1200px + 2rem);
  max-width: calc(100% - 1.5rem);
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; }
  .global-nav-cnt .cnt-left {
    width: 23%;
    flex-shrink: 0; }
  .global-nav-cnt .cnt-center {
    width: 120px;
    flex-shrink: 0; }
  .global-nav-cnt .cnt-right {
    width: 23%;
    flex-shrink: 0;
    justify-content: flex-end;
    display: flex; }
  .global-nav-cnt .tel {
    color: #FF625A;
    line-height: 1.4; }
    .global-nav-cnt .tel .number {
      font-size: 1.6rem;
      font-weight: bold; }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .global-nav-cnt {
    max-width: 80%; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .global-nav-cnt {
    width: calc(1000px + 2rem);
    max-width: calc(100% - 1.5rem); } }

@media screen and (max-width: 980px) {
  .global-nav-cnt {
    color: #fff; }
    .global-nav-cnt .cnt-left {
      display: none; }
    .global-nav-cnt .cnt-center {
      width: 5rem; }
    .global-nav-cnt .cnt-right nav {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: -100vw;
      background-color: #4FC994;
      transition: all .6s; }
      .global-nav-cnt .cnt-right nav.open {
        left: 0; }
  .site-title-box {
    display: none; }
  .menu-btn {
    display: block;
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-50%); }
  .menu-btn {
    width: 2rem;
    height: 1.25rem; } }

.single-header {
  margin: 0 0 2rem;
  text-align: center; }
  .single-header .title {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    position: relative; }
    .single-header .title::after {
      content: "";
      width: 3rem;
      height: 2px;
      background: #FFCF48;
      position: absolute;
      bottom: -1rem; }

@media screen and (max-width: 980px) {
  .single-header {
    padding: 1rem;
    text-align: left; }
    .single-header .title {
      font-size: 1.75rem;
      line-height: 1.4; } }

.kv {
  position: relative; }

.to-scroll-wrap {
  position: absolute;
  bottom: 62px;
  left: 0;
  width: 100%; }

.to-scroll {
  width: 1.5rem;
  height: 3.25rem;
  background-color: #fff;
  border: 1px solid #FF625A;
  border-radius: .75rem; }
  .to-scroll .line {
    content: "";
    width: 6px;
    height: 45%;
    border-radius: 50px;
    background-color: #FF625A;
    z-index: 1;
    overflow: hidden; }
    .to-scroll .line:after {
      content: "";
      width: inherit;
      height: 100%;
      background-color: #ffa1a1;
      z-index: 2;
      position: relative;
      animation: animation-haniwaman 2s linear 0s infinite normal; }

@keyframes animation-haniwaman {
  0% {
    top: -100%; }
  25% {
    top: -25%; }
  50% {
    top: 100%; }
  100% {
    top: 200%; } }

@media screen and (max-width: 600px) {
  .to-scroll-wrap {
    bottom: 0px; } }

.pagination-single {
  margin-top: 2rem; }
  .pagination-single a {
    padding: .5rem;
    margin-right: .5rem;
    color: #464646; }
    .pagination-single a:last-child {
      margin-right: 0; }
    .pagination-single a:hover {
      color: #0090D5; }

.interview-tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: .5rem;
  border-radius: 3rem 3rem 0 0;
  padding: 2rem 4rem 1.5rem;
  background-color: #fff; }
  .interview-tab .item {
    cursor: pointer;
    padding-bottom: .5rem; }
    .interview-tab .item:hover {
      opacity: .5; }
    .interview-tab .item.select {
      border-bottom: 1px solid #FF625A;
      color: #FF625A;
      font-weight: bold; }

.interview-box {
  background-color: #fff;
  border-radius: 0 0 3rem 3rem;
  padding: 2rem 4rem 1.5rem; }
  .interview-box img {
    width: 100%; }
  .interview-box figure {
    margin: 2rem auto; }
  .interview-box p {
    margin-bottom: 2rem; }
  .interview-box iframe {
    margin: .5rem auto; }
  .interview-box .item {
    display: none; }
    .interview-box .item.select {
      display: block; }
    .interview-box .item .title {
      color: #464646;
      font-weight: normal;
      margin-bottom: 1.5rem; }
    .interview-box .item .text {
      margin: 0; }
    .interview-box .item .content {
      margin-bottom: 2.5rem; }

@media screen and (max-width: 980px) {
  .interview-box {
    padding: 2rem 2rem 1.5rem; }
    .interview-box .item .display-table {
      display: block; }
      .interview-box .item .display-table .cell {
        display: block;
        width: 100%;
        padding: 1rem 0; }
        .interview-box .item .display-table .cell:first-child {
          padding-bottom: 0; } }

.visible-mb {
  display: none; }

@media screen and (max-width: 980px) {
  .dn {
    display: block; }
  .tl-section {
    padding: 4rem 0; }
  .hidden-mb {
    display: none; }
  .visible-mb {
    display: block; } }
