.footer-bottom {
	padding: 1rem 0;
}
.footer-about {
	font-size: 14px;
	justify-content: space-between;
	margin-bottom: .5rem;
	.logo {
		flex-basis: 25%;
	}
	.address {
		flex-basis: 70%;
		.title {
			font-size: 1.75rem;
			font-weight: bold;
			margin-bottom: .25rem;
		}
	}
}

.footer-nav {
	display: flex;
	font-size: 14px;
	& > .item {
		display: flex;
		margin-right: 1rem;
		a,span {
			&::before {
				content: "";
				background: url(../images/icon/arrow-r.svg) no-repeat;
				background-size: contain;
				width: 1rem;
				height: 1rem;
				display: inline-block;
				position: relative;
				top: 2px;
			}
		}
		a {
			&:hover {
				color: $blue;
			}
		}
		&.sub {
			display: block;
		}
		.subnav {
			margin: .5rem 0 0 1rem;
			a,span {
				&::before {
					background: url(../images/icon/list-item.svg) no-repeat;
					height: 10px;
					margin-right: .5rem;
				}
			}
			.item {
				margin-bottom: .25rem;
				font-size: 12px;
			}
		}
	}
}
.service-list {
	margin: 0 !important;
	list-style: none !important;
	.thumb {
		overflow: hidden;
		.img {
			border-radius: 1rem;
			transition: all .6s;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.desc {
		margin: 1.5rem auto;
	}
}


.article-list {
	margin: 0rem auto 2rem;
	.item {
		margin-bottom: 1rem;
		padding-bottom: .75rem;
		border-bottom: 4px dotted $gray;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.article-box {
	.title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		transition: all .4s;
		position: relative;
		left: 0;
		padding: .5rem;
		font-size: 1rem;
		font-family: $basefont;
		font-weight: normal;
	}
	.date {
		margin-bottom: .5rem;
	}
	&:hover {
		.title {
			text-decoration: underline;
			left: 1rem;
			background-color: #f8f8f8;
		}
	}
}

.footer-contact {
	.img {
		background: url(../images/thumb/contact.jpg) no-repeat center;
		background-size: cover;
	}
	.cnt {
		background-color: $green;
		padding: 4rem;
		img {
			width: 38rem;
		}
	}
}

.copyright {
	text-align: center;
}




@include mq(slaptop) {
	.service-list .desc {
		height: 6rem;
	}
}

@include mq(wtab) {
	.service-list .desc {
		height: 7rem;
	}
	.footer-nav > .item {
		display: flex;
		margin-right: 1rem;
		font-size: 1vw;
	}
	.footer-about .address {
		font-size: 1vw;
	}
}

@include mq {
	.slick-dots {
		bottom: -0.5rem !important;
	}
	.article-list {
		padding: 1.5rem;
		time {
			display: block;
			margin-bottom: .5rem;
		}
		a {
			line-height: 1.4;
		}
	}
	.footer-about {
		flex-wrap: wrap;
		display: flex !important;
		.item-l,.item-r {
			flex-basis: 100% !important;
		}
		.item-l {
			order: 2;
		}
		.item-r {
			padding: 1rem;
			margin: 0;
		}
	}
}

@include mq(ssp) {
	.service-list {
		display: block !important;
		.item {
			margin-bottom: 2rem;
		}
		.desc {
			margin: 1rem auto;
		}
	}
	.article-list {
		padding: 1.5rem;
		border: 1px solid #ebebeb;
	}
	.footer-contact {
		.img {
			height: 11rem;
		}
		.cnt {
			padding: 1rem;
		}
	}
	.footer-about {
		display: block !important;
		margin-bottom: 1.5rem;
		.logo {
			width: 50%;
			margin: 0 auto;
			margin-bottom: 2rem;
		}
	}
	.footer-nav {
		display: block;
		margin-top: 1.5rem;
		.item {
			margin-bottom: .5rem;
		}
	}
}
